.PreOrderActionIcons {
  display: flex;
  gap: .5rem;
  font-size: 18px;
}

.PreOrderActionIcons-green {
  color: #009688;
}

.PreOrderActionIcons-blue {
  color: #1e07f3;
}

.PreOrderActionIcons-gray {
  color: #666;
}
