.OrderActionIcons {
  display: flex;
  gap: .5rem;
  font-size: 18px;
  justify-content: center;
}

.OrderActionIconsDirection {
  display: flex;
  gap: .5rem;
  font-size: 18px;
  flex-direction: column;
}

.OrderActionIcons-green {
  color: #009688;
}

.OrderActionIcons-blue {
  color: #1e07f3;
}

.OrderActionIcons-gray {
  color: #666;
}
