.OrderResumen {
  display: flex;
  align-items: center;
  gap: 1rem;
}
  
.OrderResumen__label {
  padding: 0 .25rem;
  vertical-align: middle;
}
  