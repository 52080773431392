.Navbar__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 42px;
  padding: 0 0.8rem;
}

.Navbar__logo_menu {
  display: grid;
  grid-template-columns: 40px auto;
  column-gap: 0.6rem;
  line-height: 1.4rem;
  align-items: center;
}

.tiktok {
  font-size: 1.3rem;
  color: #fff;

  display: inline-block;
  transition: color 0.3s ease;
  animation: shake-cycle 3s infinite;
}

.tiktok:hover {
  color: #25f4ee;
}

.instagram {
  font-size: 1.3rem;
  color: #ff5f94;

  display: inline-block;
  transition: color 0.3s ease;
  animation: shake-cycle 3s infinite;
}

.instagram:hover {
  color: #e1306c;
}

.whatsapp {
  font-size: 1.3rem;
  color: #25d366;

  display: inline-block;
  transition: color 0.3s ease;
  animation: shake-cycle 3s infinite;
}

.whatsapp:hover {
  color: #1c9c4d;
}

/* Animación Shake Ciclica */
@keyframes shake-cycle {
  0%,
  75% {
    /* Quieta durante el 75% del ciclo */
    transform: translateX(0);
  }
  80% {
    /* Comienza el efecto */
    transform: translateX(-2px);
  }
  85% {
    transform: translateX(2px);
  }
  90% {
    transform: translateX(-2px);
  }
  95% {
    transform: translateX(2px);
  }
  100% {
    /* Regresa al estado original */
    transform: translateX(0);
  }
}
