/* Define la animación cíclica */
@keyframes shake-cycle {
  0%,
  25% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-5px);
  }
  20% {
    transform: translateX(5px);
  }
  30% {
    transform: translateX(-5px);
  }
  40% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(0);
  }
}

/* Clase para el botón */
.shake-button {
  color: #c66809;
  background: #ffedd5;
  border-radius: 30px;
  height: 36px;
  font-weight: 600;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 260px;

  animation: shake-cycle 3s ease-in-out infinite;
}

.shake-button:hover {
  background-color: #f0dabd;
}
