.LayoutApp {
  height: 100vh;
  display: flex;
  overflow: auto;
  flex-direction: row;
}

.LayoutApp__collapsible {
  color: #fff;
  font-size: 1.35rem;
  padding: 0.5rem;
}

.LayoutApp__content {
  box-sizing: border-box;
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
