.floating-container {
  display: flex;
  align-items: center;
  gap: 10px; /* Espacio entre el ícono y el texto */
}

.floating-text {
  font-size: 13px;
  color: #FFFFFF;
  padding: 5px 16px ;
  background-color: rgba(24, 144, 255, 0.8);
  border-radius: 15px;
  transform: translateX(400%); /* Comienza mucho más a la derecha */
  opacity: 0;
  transition: transform 5s ease-out, opacity 5s ease-out; /* Transición más lenta y suave */
}

.enlarge {
  transform: scale(1.1);
}

.shrink {
  transform: scale(1);
}

.floating-button {
  padding: 5px 12px;
  transition: transform 1s ease, background-color 0.3s ease;
}

.floating-button.hovered {
  transform: scale(1.12); /* Se agranda cuando el texto es hover */
  background-color: #ff4d4f;
}

.floating-button:hover {
  transform: scale(1.12);
  background-color: #ff4d4f;
}

.floating-container:hover .floating-text {
  font-size: 14px;
  transform: scale(1.1);
  background-color: #ff4d4f;
}

.show {
  transform: translateX(0); /* Se mueve a su posición final */
  opacity: 1;
  transition: transform 5s ease-out, opacity 5s ease-out; /* Transición mucho más lenta */
}
