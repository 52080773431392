.DeliveryActionIcons {
    display: flex;
    gap: .5rem;
    font-size: 18px;
  }
  
  .DeliveryActionIcons-green {
    color: #009688;
  }
  
  .DeliveryActionIcons-blue {
    color: #1e07f3;
  }
  
  .DeliveryActionIcons-gray {
    color: #666;
  }
  