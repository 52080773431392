.ProductListSearch__empty {
    border: 1px solid #eee;
    color: #999;
    padding: .65rem;
    text-align: center;
    margin-bottom: .65rem;
  }
  
  .ProductListSearch__grid {
    display: grid;
    grid-template-columns: 36px auto 96px 96px 48px;
    gap: .5rem;
    padding: .25rem 0;
  }
  
  .ProductListSearch__head {
    color: #666;
    border-bottom: 1px solid #eee;
  }
  
  .ProductListSearch__footer {
    display: grid;
    grid-template-columns: auto 96px 48px;
    gap: .5rem;
    border-top: 1px solid #eee;
    padding: .25rem 0;
  }
  