.OrderActionIcons {
  display: flex;
  gap: .5rem;
  font-size: 18px;
}

.OrderActionIcons-green {
  color: #009688;
}

.OrderActionIcons-blue {
  color: #1e07f3;
}

.OrderActionIcons-gray {
  color: #666;
}

.OrderActionIcons-blue-light {
  color: #29B6F6;
}

.OrderActionIcons-red {
  color: #D32F2F;
}
