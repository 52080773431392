.WrapperContainer {
  background-repeat: 'no-repeat';
  background-position: center;
  background-size: cover;
  overflow: auto;
  height: 100%;

  /* display */
  display: grid;
  align-items: center;
  justify-content: center;
}

.WrapperBoxSmall {
  padding: 1rem 2rem;
  margin: 1rem;
  border-radius: 16px;
  background-color: #ffffff;
}
