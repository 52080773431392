.OrderResumen {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.OrderResumen__label {
  padding: 0 .15rem;
  vertical-align: middle;
  font-size: small;
}
