.PreorderTable__columns-whatsappicon{
    font-size: 20px;
    color: #009688;
}
.PreorderTable__columns-whatsappicon:hover{
    color: #105f57;
}
.PreorderTable__columns-phoneicon{
    font-size: 20px;
    margin-left: 15px;
    color: #1e07f3;
}
.PreorderTable__columns-phoneicon:hover{
    font-size: 20px;
    color: #23197e;
}