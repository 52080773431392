.ProductGridContainer {
  padding: 0 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
}

/* product card */
.ProductCard {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ProductCardMobile {
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

/* product body */
.ProductCardBodyContainer {
  padding: 1rem 1rem 1.5rem;
  display: grid;
  height: 100%;
  align-items: stretch;
}

.ProductCardBodyContainerMobile {
  padding: 0.5rem 0.5rem 0.5rem 0.8rem;
  width: 100%;
}

.ProductCardBody {
  flex-grow: 1;
  font-size: 12px;
}

/* image */
.ProductCardImage {
  width: 100%;
  height: 200px !important;
  object-fit: cover;
  overflow: hidden;
}
.ProductCardImageMobile {
  width: 140px !important;
  height: auto !important;
  border-radius: 8px;
  margin-left: 0.5rem;
}

.ProductProvider {
  margin: 0.5rem 0;
}
.ProductProvider span {
  font-weight: 500;
}
.ProductName {
  font-size: 13.5px;
  font-weight: 500;
  margin: 0.65rem 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.ProductPrice {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}
.ProductPrice span {
  color: #666;
}
.ProductPrice .ProductPrice__price {
  font-weight: 500;
  font-size: 14px;
}
.ProductStock {
  margin-top: 0.5rem;
}

.ProductCardFooter {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 16px;
}
