.container {
  padding: 0 24px;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

/* Ant custom */
.ant-form-item-explain-error {
  font-size: 12px;
}

.ant-form-item {
  margin-bottom: 12px;
}

.ant-layout-header {
  line-height: 42px;
}

.ant-table-wrapper,
.ant-table-wrapper .ant-spin-nested-loading,
.ant-table-wrapper .ant-spin-container,
.ant-table-wrapper .ant-table,
.ant-table-wrapper .ant-table .ant-table-container,
.ant-table-wrapper .ant-table .ant-table-container .ant-table-content {
  /* height: 100%; */
  display: flex;
  flex-direction: column;
}

.ant-table-row-expand-icon {
  border: 1px solid #222;
}

.ant-table.ant-table-small
  .ant-table-tbody
  .ant-table-wrapper:only-child
  .ant-table {
  margin-left: 10px;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: -5px;
}

.ant-table.ant-table-small .ant-table-tbody > tr > td {
  padding: 4px;
}

.ant-table-cell {
  padding: 4px 8px !important;
}

.fullWidth .ant-form-item-control {
  max-width: 100%;
}

.ant-form-vertical .ant-form-item-label {
  padding-bottom: 4px !important;
}
